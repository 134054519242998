import {
  FormControl,
  InputBase,
  Select,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import React from "react";

const BootstrapInput = withStyles((theme) => ({
  root: {
    width: "100%",
    display: "block",
    "label + &": {},
  },
  input: {
    display: "block",
    borderRadius: 4,
    width: "100%",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "7px 26px 7px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: ["Source Sans Pro"].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

export default function SLASelect(props: any) {
  const slas = props.value;

  const addClassification = () => {
    const classification = {
      classificationId: "",
      slaHours: 0,
      amberThresholdHours: 0,
    };

    props.onChange([...slas, classification]);
  };

  const change = (value: any, name: string, index: number) => {
    const updatedSla = [...slas];
    updatedSla[index][name] = value;

    props.onChange([...slas]);
  };

  const deleteSla = (index: number) => {
    slas.splice(index, 1);
    props.onChange([...slas]);
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Classification</th>
          <th>SLA Time</th>
          <th>Warning Time</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {slas.map((sla: any, index: number) => {
          return (
            <tr key={index}>
              <td>
                <div className="form-group mb-0">
                  <FormControl>
                    <Select
                      displayEmpty
                      input={<BootstrapInput />}
                      value={sla.classificationId}
                      onChange={(e: any) => {
                        return change(
                          Number(e.target.value),
                          "classificationId",
                          index
                        );
                      }}
                    >
                      <MenuItem value="">Select Classification</MenuItem>
                      {props.options?.map((option: any, i: number) => {
                        if (
                          slas.filter(
                            (s: any) => s.classificationId === option.id
                          ).length === 0 ||
                          slas.findIndex(
                            (s: any) => s.classificationId === option.id
                          ) === index
                        ) {
                          return (
                            <MenuItem value={option.id.toString()} key={i}>
                              {option.label}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </div>
              </td>
              <td>
                <div className="form-group mb-0">
                  <input
                    type="number"
                    value={sla.slaHours}
                    onChange={(e: any) =>
                      change(Number(e.target.value), "slaHours", index)
                    }
                    className="form-control"
                    id={`slaHours-${index}`}
                    aria-describedby="emailHelp"
                  />
                </div>
              </td>
              <td>
                <div className="form-group mb-0">
                  <input
                    type="number"
                    value={sla.amberThresholdHours}
                    onChange={(e: any) =>
                      change(Number(e.target.value), "amberThresholdHours", index)
                    }
                    className="form-control"
                    id={`amberThresholdHours-${index}`}
                    aria-describedby="emailHelp"
                  />
                </div>
              </td>
              <td>
                <button
                  className="btn btn-link"
                  onClick={() => deleteSla(index)}
                >
                  <i id={`delete-${index}`} className="fas fa-trash text-danger"></i>
                </button>
              </td>
            </tr>
          );
        })}
      </tbody>

      <tfoot>
        <tr>
          <td colSpan={4}>
            <button
              className="btn btn-primary btn-sm float-right"
              onClick={addClassification}
            >
              Add Classification
            </button>
          </td>
        </tr>
      </tfoot>
    </table>
  );
}
