import { FormControl } from "@material-ui/core";
import React, { FC } from "react";

export type SelectOption = {
  id: number;
  label: string;
};

type Props = {
  label: string;
  name: string;
  onChange: (id: number|null) => void;
  id: number|null;  
  options: SelectOption[];
  notNull?: boolean;
};

const SelectInput: FC<Props> = ({ label, name, onChange, id, options, notNull = false}) => {

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <FormControl>
        <select 
          name={name}
          id={name}
          value={id !== null ? id.toString() :  ""}
          onChange={(event):void => 
            event.target.value === ""
              ? onChange(null)
              : onChange(Number(event.target.value))           
          }
        >
          {!notNull && <option value="">{`Select ${label}`}</option>}
          {options.map((option: SelectOption, i: number) => {
            return (
              <option value={option.id.toString()} key={i}>
                {option.label}
              </option>
            );
          })}
        </select>
      </FormControl>
    </div>
  );
};
export default SelectInput;