import { Header } from "./shared/data-table/table-header"; 

export const slaStatusOptions = [
  {
    id: "Amber",
    label: "Amber",
  },
  {
    id: "Green",
    label: "Green",
  },
  {
    id: "Red",
    label: "Red",
  },
];

export const statusOptions = [
  {
    id: "PendingAi",
    label: "Pending AI",
  },
  {
    id: "AwaitingCustomer",
    label: "AwaitingCustomer",
  },
  {
    id: "Claimed",
    label: "Claimed",
  },
  {
    id: "Closed",
    label: "Closed",
  },
  {
    id: "Successful",
    label: "Successful",
  },
  {
    id: "Unclaimed",
    label: "Unclaimed",
  },
  {
    id: "Unsuccessful",
    label: "Unsuccessful",
  },
];



const caseId = { id: "caseId", label: "Case#", width: "172px" };
const client = { id: "client", label: "Client#", width: null }; 
const clientCaseId = { id: "clientCaseId", label: "Account#", width: null };
const slatime = { id: "slatime", label: "Time Remaining", width: "172px" };
const investorEmail = { id: "investorEmail", label: "Email", width: null };
const subject = { id: "subject", label: "Subject", width: "300px" };
const description = { id: "description", label: "Description", width: "300px" };
const classification = { id: "classification", label: "Classification", width: null };
const team = { id: "team", label: "Team", width: null };
const status = { id: "status", label: "Status", width: null };
const owner = { id: "owner", label: "Owner", width: null };
const aiProbability = { id: "aiProbability", label: "Confidence", width: null };
const createdDate = { id: "createdDate", label: "Create Date", width: "139px" };

export const tableHeaders: Array<Header|undefined> = [
  caseId,
  client,
  clientCaseId,
  slatime,
  investorEmail,
  subject,
  description,
  classification,
  team,
  status,
  owner,
  aiProbability,
  createdDate
];